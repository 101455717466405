import {ExerciseInstructionModel} from "./Exercise";

export const ExerciseTranslationModel = (
  exercise_id,
  language,
  name,
  audio_url,
  instructions
) => {
  return {
    exercise_id: {
      value: exercise_id,
      hidden: true,
    },
    language: {
      value: language,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: name,
    },
    audio_url: {
      type: "input",
      value: audio_url,
    },

		instructions: {
      type: 'collection',
      value: instructions,
      prototype: ExerciseInstructionModel(),
      onChangeEvent: 'handleCollection',
			requestNormalizer: (data) => {
				return data.map(obj => obj.name);
      },
		},
  };
};

export const ExerciseTranslationFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    name: {
      type: "input",
      value: "",
    },
  };
};
