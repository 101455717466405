import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";


class ImportButton extends Component {

    constructor(props) {
        super(props);
        this.text = props.text || "Import"
    }

    onClick = () => {
        const confirmText = this.props.confirmText || "Start Import?";
        if (window.confirm(confirmText)) this.props.onConfirmed()
    }

    render() {
        return <Button
            className="btn btn-danger"
            onClick={this.onClick}
        >
            {this.text}
        </Button>
    }
}

export default ImportButton;
