import BaseAddEditComponent from "controllers/Generic/BaseAddEditComponent";
import { ChallengeTranslationModel } from "models/ChallengeTranslationModel";

import { URL_BASE } from "./lib";


export default class ChallengeTranslation_Add extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Challenge Translation";
        this.url = URL_BASE;
        this.skipPrepopulation = true;
    }

    locationState = () => {
        return this.props.location.state || { language: {}, translation: {} };
    };

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`;
    };

    getEntity = () => {
        let state = this.locationState();
        let translation = state.translation || {};

        return ChallengeTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || "",
            translation.description || "",
        );
    };
}
