import React from 'react';
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {WorkoutTypeModel} from "../../models/WorkoutType";

export default class WorkoutTypeAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Workout Type"
        this.url = "/admin/workouts/types"

    }

    getEntity = () => {
        return WorkoutTypeModel();
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}