import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import { BranchTranslationModel } from "../../../models/BranchTranslation";

export default class BranchTranslationAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "Branch Translation";
    this.url = "/admin/branch/translations";
    this.skipPrepopulation = true;
  }

  locationState = () => {
    return this.props.location.state || { language: {}, translation: {} };
  };

  getTitle = () => {
    return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${
      this.locationState().language.title
    }`;
  };

  getEntity = () => {
    let state = this.locationState();
    let translation = state.translation || {};
    return BranchTranslationModel(
      state.resource || null,
      state.language.key || null,
      translation.name || null,
    );
  };
}
