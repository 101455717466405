import {ExerciseModel} from "../../models/Exercise";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {API} from "aws-amplify";
import debounce from "debounce-promise";


export default class ExerciseAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Exercise"
        this.url = "/admin/exercises"
        this.redirectUrl = "/admin/exercises"

        this.debouncedExerciseLoadOptions = debounce(loadObject("/admin/exercises"), 2000);
        this.debouncedTipsLoadOptions = debounce(loadObject("/admin/exercises/tips"), 2000);

    }

    getEntity = () => {
        return ExerciseModel(this.context.constants, this.debouncedExerciseLoadOptions, this.context.exercises_data, this.debouncedTipsLoadOptions);
    }
}


function loadObject(url) {
  return (input) => {
        let queryParams = {
        }
        if (input.length > 0) {
            if (isNaN(input)) {
                queryParams["name"] = input;
            } else {
                queryParams["ids"] = JSON.stringify([parseInt(input)]);
            }
        }

        return API.get("admin", url, {
            queryStringParameters: queryParams,
        })
            .then((data) => {

                let exercises = data.items.reduce(function (obj, item) {
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(exercises);
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }
}