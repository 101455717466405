import React, {Component} from "react";
import List from "../../../components/List/List";
import {EquipmentFilter} from "../../../models/Equipment";
import {ApiConstantsContext} from "../../../variables/ApiConstantsContext";

export default class Equipment extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/exercises/equipments/add';
        this.editPath = '/admin/exercises/equipments/edit';
    }

    render() {
        return (
            <List
                name="Equipment"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/exercises/equipments"
                listApiUrl='/admin/exercises/equipments'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={EquipmentFilter()}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }
}
