import React from 'react';
import {Workout} from "../../models/Workout";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import debounce from "debounce-promise";
import {API} from "aws-amplify";


export default class WorkoutAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Workout"
        this.url = "/admin/workouts"
        this.debouncedLoadOptions = debounce(this.loadBlocks, 2000);
    }

    loadBlocks = (input) => {
        let queryParams = {
        }
        if (input.length > 0) {
            if (isNaN(input)) {
                queryParams["name"] = input;
            } else {
                queryParams["ids"] = JSON.stringify([parseInt(input)]);
            }
        }

        return API.get("admin", "/admin/workout-blocks", {
            queryStringParameters: queryParams,
        })
            .then((data) => {

                let blocks = data.items.reduce(function (obj, item) {
                    // item.body_parts = to_const(item.body_parts, "body_part");
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(blocks);
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    };

    getEntity = () => {
        return Workout(this.context.constants, this.debouncedLoadOptions, this.context.workouts_data);
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}