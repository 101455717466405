import {enumToOptions, selector} from "../inputs/selector";
import {mapString} from "../inputs/utils";

export const BranchModel = (constants) => {
	return {
		name: {
			type: "input",
			validationRules: "required",
			value: "",
		},
		preview: {
			type: 'input',
			validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }, 'required'],
			value: null,
		},
		workout_preview: {
			type: 'input',
			validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }, 'required'],
			value: null,
		},
		gender: selector(
            enumToOptions(constants, "gender"),
            mapString,
            { validationRules: "required" },
        ),
	}
}