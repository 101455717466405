export const ExerciseTipTranslationModel = (
  tip_id,
  language,
  name,
  audio_url
) => {
  return {
    tip_id: {
      value: tip_id,
      hidden: true,
    },
    language: {
      value: language,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: name,
    },
	  audio_url: {
			type: 'input',
			validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
			value: audio_url,
		}
  };
};