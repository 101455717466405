import React, { Component } from "react";
import Form from "../../components/Form/Form";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";


export default class BaseAddEditComponent extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.name = "";
        this.url = "";
        this.redirectUrl = undefined;
        this.submitUrl = undefined;
        this.skipPrepopulation = false;
        this.md = props.md || 6
    }

    onDataCollected = (data) => {
        // Override to post-process data collected from form
        return data
    }

    onDataReceived = (data) => {
        // Override to transform data before it'll be placed into form
        return data
    }

    onSuccess = () => {
        this.props.handleClick(
            this.isEdit() ?
                `${this.name} ${this.props.match.params.id} successfully updated` :
                `${this.name} successfully created`,
            "success", "tr"
        );
        this.props.history.push(this.redirectUrl || this.url);
    };

    getEntity = () => {
        throw new TypeError(this.constructor.name + " must implement getEntity");
    }

    isEdit = () => {
        return Boolean(this.props.match.params.id)
    }

    getTitle = () => {
        return this.isEdit() ? `Edit ${this.name}` : `Add ${this.name}`
    }
    
    render() {
        let userReadOnly
        if (this.props.location.state) {
            userReadOnly = this.props.userHasOnlyRestrictedPermission(this.props.location.state.readOnlyGroups, this.props.location.state.allowedGroups)
        } else {
            userReadOnly = false
        }

        return <Form
            entity={this.getEntity()}
            title={this.getTitle()}
            url={this.submitUrl || this.url}
            onSuccess={this.onSuccess}
            onDataCollected={this.onDataCollected}
            onDataReceived={this.onDataReceived}
            handleClick={this.props.handleClick}
            id={this.props.match.params.id}
            skipPrepopulation={this.skipPrepopulation}
            md={this.md}
            userReadOnly={userReadOnly}
        />
    }
}