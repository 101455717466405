import defaults from "./defaults";

import Info from "./controllers/Info";

import Workout from "./controllers/Workout/Workout.jsx";
import WorkoutAdd from "./controllers/Workout/WorkoutAdd";
import WorkoutEdit from "./controllers/Workout/WorkoutEdit";

import WorkoutBlock from "./controllers/WorkoutBlock/WorkoutBlock";
import WorkoutBlockAdd from "./controllers/WorkoutBlock/WorkoutBlockAdd";
import WorkoutBlockEdit from "./controllers/WorkoutBlock/WorkoutBlockEdit";

import Exercise from "./controllers/Exercises/Exercise";
import ExerciseAdd from "./controllers/Exercises/ExerciseAdd";
import ExerciseEdit from "./controllers/Exercises/ExerciseEdit";
import ExerciseTranslation from "./controllers/Translation/Exercise/Exercise";
import ExerciseTranslationAdd from "./controllers/Translation/Exercise/ExerciseAdd";
import ExerciseTranslationEdit from "./controllers/Translation/Exercise/ExerciseEdit";

import Equipment from "./controllers/Exercises/Equipments/Equipment";
import EquipmentAdd from "./controllers/Exercises/Equipments/EquipmentAdd";
import EquipmentEdit from "./controllers/Exercises/Equipments/EquipmentEdit";

import Tag from "./controllers/Exercises/Tags/Tag";
import TagAdd from "./controllers/Exercises/Tags/TagAdd";
import TagEdit from "./controllers/Exercises/Tags/TagEdit";

import WorkoutTranslation from "./controllers/Translation/Workout/Workout";
import WorkoutTranslationAdd from "./controllers/Translation/Workout/WorkoutAdd";
import WorkoutTranslationEdit from "./controllers/Translation/Workout/WorkoutEdit";

import BodyPart from "./controllers/Exercises/BodyParts/BodyPart";
import BodyPartAdd from "./controllers/Exercises/BodyParts/BodyPartAdd";
import BodyPartEdit from "./controllers/Exercises/BodyParts/BodyPartEdit";
import BodyPartTranslation from "./controllers/Translation/BodyPart/BodyPart";
import BodyPartTranslationAdd from "./controllers/Translation/BodyPart/BodyPartAdd";
import BodyPartTranslationEdit from "./controllers/Translation/BodyPart/BodyPartEdit";

import WorkoutTypeTranslation from "./controllers/Translation/WorkoutType/WorkoutType";
import WorkoutTypeTranslationAdd from "./controllers/Translation/WorkoutType/WorkoutTypeAdd";
import WorkoutTypeTranslationEdit from "./controllers/Translation/WorkoutType/WorkoutTypeEdit";
import WorkoutType from "./controllers/WorkoutType/WorkoutType";
import WorkoutTypeAdd from "./controllers/WorkoutType/WorkoutTypeAdd";
import WorkoutTypeEdit from "./controllers/WorkoutType/WorkoutTypeEdit";

import ExerciseTip from "./controllers/Exercises/Tips/Tip";
import ExerciseTipAdd from "./controllers/Exercises/Tips/TipAdd";
import ExerciseTipEdit from "./controllers/Exercises/Tips/TipEdit";
import ExerciseTipTranslation from "./controllers/Translation/Tips/Tip";
import ExerciseTipTranslationAdd from "./controllers/Translation/Tips/TipAdd";
import ExerciseTipTranslationEdit from "./controllers/Translation/Tips/TipEdit";

import WbEquipmentTranslation from "./controllers/Translation/WbEquipment/WbEquipmentTranslation";
import WbEquipmentTranslation_Add from "./controllers/Translation/WbEquipment/WbEquipmentTranslation_Add";
import WbEquipmentTranslation_Edit from "./controllers/Translation/WbEquipment/WbEquipmentTranslation_Edit";

import AudioTrack from "./controllers/AudioTrack/AudioTrack";
import AudioTrackAdd from "./controllers/AudioTrack/AudioTrackAdd";
import AudioTrackEdit from "./controllers/AudioTrack/AudioTrackEdit";

import AudioTrackTranslation from "./controllers/Translation/AudioTrack/AudioTrackTranslation";
import AudioTrackTranslation_Add from "./controllers/Translation/AudioTrack/AudioTrackTranslation_Add";
import AudioTrackTranslation_Edit from "./controllers/Translation/AudioTrack/AudioTrackTranslation_Edit";

import AudioTrackCategory from "./controllers/AudioTrackCategory/AudioTrackCategory";
import AudioTrackCategoryAdd from "./controllers/AudioTrackCategory/AudioTrackCategoryAdd";
import AudioTrackCategoryEdit from "./controllers/AudioTrackCategory/AudioTrackCategoryEdit";

import WbWorkoutAdd from "controllers/WbWorkout/WbWorkoutAdd";
import WbWorkoutEdit from "controllers/WbWorkout/WbWorkoutEdit";
import WbWorkoutList from "controllers/WbWorkout/WbWorkoutList";

import ChallengeAdd from "controllers/Challenge/ChallengeAdd";
import ChallengeEdit from "controllers/Challenge/ChallengeEdit";
import ChallengeList from "controllers/Challenge/ChallengeList";

import ChallengeTranslation_Add from "controllers/Translation/Challenge/ChallengeTranslation_Add";
import ChallengeTranslation_Edit from "controllers/Translation/Challenge/ChallengeTranslation_Edit";
import ChallengeTranslation_List from "controllers/Translation/Challenge/ChallengeTranslation_List";
import ContentfulSync from "./controllers/Contentful/ContentfulSync";

import Branch from "./controllers/Branch/Branch";
import BranchAdd from "./controllers/Branch/BranchAdd";
import BranchEdit from "./controllers/Branch/BranchEdit";

import BranchTranslation from "./controllers/Translation/Branch/Branch"
import BranchTranslationAdd from "./controllers/Translation/Branch/BranchAdd"
import BranchTranslationEdit from "./controllers/Translation/Branch/BranchEdit"

let g = defaults.userGroups;

const dashboardRoutes = [
  //  Exercises
  {
    exact: true,
    path: "/exercises",
    name: "Exercise",
    icon: "pe-7s-config",
    component: Exercise,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/exercise/add",
    name: "Exercise Add",
    icon: "pe-7s-config",
    component: ExerciseAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/exercise/edit/:id",
    name: "Exercise Edit",
    icon: "pe-7s-config",
    component: ExerciseEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // BodyPart
  {
    exact: true,
    submenu: true,
    path: "/exercises/body_parts",
    name: "Body Parts",
    component: BodyPart,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/exercises/body_parts/add",
    name: "Body Part Add",
    icon: "pe-7s-albums",
    component: BodyPartAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/exercises/body_parts/edit/:id",
    name: "Body Part Edit",
    icon: "pe-7s-albums",
    component: BodyPartEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Equipment
  {
    exact: true,
    submenu: true,
    path: "/exercises/equipments",
    name: "Equipments",
    component: Equipment,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/exercises/equipments/add",
    name: "Equipments Add",
    icon: "pe-7s-albums",
    component: EquipmentAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/exercises/equipments/edit/:id",
    name: "Equipment Edit",
    icon: "pe-7s-albums",
    component: EquipmentEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Tag
  {
    exact: true,
    submenu: true,
    path: "/exercises/tags",
    name: "Tags",
    component: Tag,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/exercises/tags/add",
    name: "Tags Add",
    icon: "pe-7s-albums",
    component: TagAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/exercises/tags/edit/:id",
    name: "Tag Edit",
    icon: "pe-7s-albums",
    component: TagEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Exercise Tips
  {
    exact: true,
    submenu: true,
    path: "/exercises/tips",
    name: "Tips",
    component: ExerciseTip,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/exercises/tips/add",
    name: "Tips Add",
    icon: "pe-7s-albums",
    component: ExerciseTipAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/exercises/tips/edit/:id",
    name: "Tip Edit",
    icon: "pe-7s-albums",
    component: ExerciseTipEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // WorkoutBLock
  {
    exact: true,
    path: "/workout-blocks",
    name: "Workout block",
    icon: "pe-7s-albums",
    component: WorkoutBlock,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/workout-blocks/add",
    name: "Workout Block Add",
    icon: "pe-7s-albums",
    component: WorkoutBlockAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/workout-blocks/edit/:id",
    name: "Workout Block Edit",
    icon: "pe-7s-albums",
    component: WorkoutBlockEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Workouts
  {
    exact: true,
    path: "/workouts",
    name: "Workout",
    icon: "pe-7s-gym",
    component: Workout,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/workouts/add",
    name: "Workout Add",
    component: WorkoutAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/workouts/edit/:id",
    name: "Workout Edit",
    component: WorkoutEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },

  // Workouts Types
  {
    exact: true,
    path: "/workouts/types",
    name: "Workout Type",
    icon: "pe-7s-gym",
    component: WorkoutType,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/workouts/types/add",
    name: "Workout Type Add",
    component: WorkoutTypeAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/workouts/types/edit/:id",
    name: "Workout Type Edit",
    component: WorkoutTypeEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },

  // WbWorkouts
  {
    exact: true,
    path: "/wb-workout",
    name: "WB Workouts",
    icon: "pe-7s-gym",
    component: WbWorkoutList,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/wb-workout/add",
    name: "WB Workout Add",
    component: WbWorkoutAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/wb-workout/edit/:id",
    name: "WB Workout Edit",
    component: WbWorkoutEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },

  // Challenges
  {
    exact: true,
    path: "/challenge",
    name: "Challenges",
    icon: "pe-7s-gym",
    component: ChallengeList,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/challenge/add",
    name: "Add Challenge",
    component: ChallengeAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/challenge/edit/:id",
    name: "Challenge Edit",
    component: ChallengeEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },

  // Audio Tracks
  {
    exact: true,
    path: "/audio-track",
    name: "Audio Tracks",
    icon: "pe-7s-albums",
    component: AudioTrack,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/audio-track/add",
    name: "Audio Track Add",
    component: AudioTrackAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/audio-track/edit/:id",
    name: "Audio Track Edit",
    component: AudioTrackEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  // Branches
  {
    exact: true,
    path: "/branch",
    name: "Branch",
    icon: "pe-7s-albums",
    component: Branch,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/branch/add",
    name: "Branch Add",
    icon: "pe-7s-albums",
    component: BranchAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
  invisible: true,
  path: "/branch/edit/:id",
  name: "Branch Edit",
  component: BranchEdit,
  layout: "/admin",
  allowedGroups: [g.admin, g.editor],
  },

  // Audio Track Category
  {
    exact: true,
    path: "/audio-track-category",
    name: "Audio Track Category",
    icon: "pe-7s-albums",
    component: AudioTrackCategory,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    invisible: true,
    path: "/audio-track-category/add",
    name: "Audio Track Category Add",
    component: AudioTrackCategoryAdd,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },
  {
    invisible: true,
    path: "/audio-track-category/edit/:id",
    name: "Audio Track Category Edit",
    component: AudioTrackCategoryEdit,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor],
  },

  // Translations exercises
  {
    exact: true,
    path: "/exercises/translations",
    name: "Translations",
    icon: "pe-7s-pen",
    component: ExerciseTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    exact: true,
    submenu: true,
    path: "/exercises/translations",
    name: "Exercises",
    component: ExerciseTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/exercises/translations/add",
    name: "",
    component: ExerciseTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/exercises/translations/edit/:id",
    name: "",
    component: ExerciseTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    exact: true,
    submenu: true,
    path: "/workouts/translations",
    name: "Workouts",
    component: WorkoutTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/workouts/translations/add",
    name: "",
    component: WorkoutTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/workouts/translations/edit/:id",
    name: "",
    component: WorkoutTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    exact: true,
    submenu: true,
    path: "/exercises/body_parts/translations",
    name: "Body Parts",
    component: BodyPartTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/exercises/body_parts/translations/add",
    name: "",
    component: BodyPartTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/exercises/body_parts/translations/edit/:id",
    name: "",
    component: BodyPartTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    exact: true,
    submenu: true,
    path: "/workouts/types/translations",
    name: "Workout Types",
    component: WorkoutTypeTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/workouts/types/translations/add",
    name: "",
    component: WorkoutTypeTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/workouts/types/translations/edit/:id",
    name: "",
    component: WorkoutTypeTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    exact: true,
    submenu: true,
    path: "/exercises/tips/translations",
    name: "Exercise Tips",
    component: ExerciseTipTranslation,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    path: "/exercises/tips/translations/add",
    name: "",
    component: ExerciseTipTranslationAdd,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    path: "/exercises/tips/translations/edit/:id",
    name: "",
    component: ExerciseTipTranslationEdit,
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },

  // WB-Equipment Translations
  {
    name: "WB-Equipments",
    component: WbEquipmentTranslation,
    path: "/workout-builder/wb-equipment-translation",
    layout: "/admin",
    exact: true,
    submenu: true,
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    name: "",
    component: WbEquipmentTranslation_Add,
    path: "/workout-builder/wb-equipment-translation/add",
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    name: "",
    component: WbEquipmentTranslation_Edit,
    path: "/workout-builder/wb-equipment-translation/edit/:id",
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },

  // Audio tracks translations
  {
    name: "Audio Tracks",
    component: AudioTrackTranslation,
    path: "/audio-track-translation",
    layout: "/admin",
    exact: true,
    submenu: true,
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    name: "",
    component: AudioTrackTranslation_Add,
    path: "/audio-track-translation/add",
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    name: "",
    component: AudioTrackTranslation_Edit,
    path: "/audio-track-translation/edit/:id",
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
    // Branch Translations
  {
    name: "Branches",
    component: BranchTranslation,
    path: "/branch/translations/",
    layout: "/admin",
    exact: true,
    submenu: true,
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    name: "",
    component: BranchTranslationAdd,
    path: "/branch/translations/add",
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    name: "",
    component: BranchTranslationEdit,
    path: "/branch/translations/edit/:id",
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  // Challenge translations
  {
    name: "Challenges",
    component: ChallengeTranslation_List,
    path: "/challenge-translation",
    layout: "/admin",
    exact: true,
    submenu: true,
    allowedGroups: [g.admin, g.editor, g.viewer],
  },
  {
    name: "",
    component: ChallengeTranslation_Add,
    path: "/challenge-translation/add",
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },
  {
    name: "",
    component: ChallengeTranslation_Edit,
    path: "/challenge-translation/edit/:id",
    layout: "/admin",
    invisible: true,
    allowedGroups: [g.admin, g.editor],
  },

  // Contentful
  {
    path: "/contentful",
    name: "Contentful",
    icon: "pe-7s-info",
    component: ContentfulSync,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
  // INFO
  {
    path: "/info",
    name: "Info",
    icon: "pe-7s-info",
    component: Info,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
];

export default dashboardRoutes;
