import {API} from "aws-amplify";
import {buildSelector, constToSelectOptions} from "../utils/Utils";
import {ExerciseOption} from "./Exercise"


export const BlockExerciseModel = (constants, loadOptions) => {
    let objCache = {}
    return {
        id: {
            value: null,
            hidden: true
        },
        exercise_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: (option) => {
                return option.id
            },
            responseNormalizer: (option) => {
                let exercise_id = option
                if (objCache[exercise_id] !== undefined) {
                    return objCache[exercise_id]
                }
                return API.get('admin', `/admin/exercises/${exercise_id}`)
                    .then(data => {
                        objCache[exercise_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                components: { ExerciseOption },
                getOptionLabel: option => {
                    return `[${option['id']}]; ${option['name']};`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
            },
            md: 4
        },
        type: {
            ...buildSelector(constToSelectOptions(constants, 'workout_exercise_type'), 'select'),
            value: null,
        },

        time: {
            inputType: 'number',
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
            md: 1
        },
        repetition: {
            inputType: 'number',
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
            md: 1
        },
    }
}


export const WorkoutBlock = (constants, loadOptions) => {
    return {
        name: {
            type: 'input',
            value: '',
            md: 5
        },
        exercises: {
            type: 'table_collection',
            value: [],
            prototype: BlockExerciseModel(constants, loadOptions),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
        },
    }
};



export const WorkoutBlockFilter = (constants) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
    };
};