import { AudioTrackModel } from "models/AudioTrack";
import BaseAddEditComponent from "controllers/Generic/BaseAddEditComponent";


export default class AudioTrackAdd extends BaseAddEditComponent {

    constructor(props) {
        super(props);
        this.name = "Audio Track";
        this.url = "/admin/audio-track";
        this.redirectUrl = "/admin/audio-track"
    }

    getEntity = () => {
        return AudioTrackModel()
    }
}