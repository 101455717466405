import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {Equipment} from "../../../models/Equipment";


export default class EquipmentAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Equipment"
        this.url = "/admin/exercises/equipments"
        this.redirectUrl = "/admin/exercises/equipments"
    }

    getEntity = () => {
        return Equipment();
    }
}
