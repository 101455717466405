import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {BodyPartTranslationFilter} from "../../../models/BodyPartTranslation";

export default class BodyPartTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Body Part Translations";
    this.addPath = "/admin/exercises/body_parts/translations/add";
    this.editPath = "/admin/exercises/body_parts/translations/edit";
    this.removeUrl = "/admin/exercises/body_parts/translations";
    this.listApiUrl = "/admin/exercises/body_parts/translations";
  }

  generateFilter = () => {
    return BodyPartTranslationFilter();
  };
}
