import { API } from "aws-amplify";


const getExerciseNameOrId = (value) => {
    return isNaN(value) ? { name: value } : { ids: JSON.stringify([Number(value)]) }
}


export const loadExercises = (value) => {
    if (!value) return;

    const queryParams = getExerciseNameOrId(value);
    return API.get("admin", "/admin/exercises", {
        queryStringParameters: queryParams,
    })
        .then(({ items }) => items)
        .catch((error) => {
            console.error(error);
            return []
        })
}


export const filterNonRest = (exercises) => {
    return exercises.filter(({ id }) => id !== 99999)
}
