import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class EditButton extends Component {
  render() {
    if (this.props.editPath) {
      return (
        <NavLink
          to={{
            pathname: this.props.editPath + "/" + this.props.id,
            state: {
              allowedGroups: this.props.allowedGroups,
              readOnlyGroups: this.props.readOnlyGroups,
              userHasOnlyRestrictedPermission:
                this.props.userHasOnlyRestrictedPermission,
            },
          }}
          className="btn btn-primary"
        >
          Edit
        </NavLink>
      );
    }

    return "";
  }
}

export default EditButton;
