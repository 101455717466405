import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {BodyPart} from "../../../models/BodyPart";


export default class BodyPartAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "BodyPart"
        this.url = "/admin/exercises/body_parts"
        this.redirectUrl = "/admin/exercises/body_parts"
    }

    getEntity = () => {
        return BodyPart();
    }
}
