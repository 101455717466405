import debounce from "debounce-promise";

import { WbWorkoutModel } from "models/WbWorkoutModel";
import BaseAddEditComponent from "controllers/Generic/BaseAddEditComponent";
import { loadExercises, filterNonRest } from "controllers/Exercises/lib";


const findBlock = (blocks, type) => {
    const results = blocks.filter(x => x.type === type);
    return results[0] || {}
}


const countUniqueNonRest = (exercises) => {
    const noRestIds = filterNonRest(exercises).map(({ id }) => id);
    const uniqueIds = [...new Set(noRestIds)];
    return uniqueIds.length
}


export default class WbWorkoutAdd extends BaseAddEditComponent {

    constructor(props) {
        super(props);
        this.name = "WB Workout";
        this.url = "/admin/entity/WbWorkout";
        this.redirectUrl = "/admin/wb-workout";
        this.debouncedLoadOptions = debounce(loadExercises, 2000);
        this.md = 12
    }

    getEntity = () => {
        return WbWorkoutModel(this.context.constants, this.debouncedLoadOptions)
    }

    onDataReceived = (data) => {
        const { blocks } = data;
        if (!blocks) return data;

        const warm_up_exercises = findBlock(blocks, "warm_up_general").exercises || [],
            cool_down_exercises = findBlock(blocks, "cool_down").exercises || [];

        const training = findBlock(blocks, "training");
        const training_repetitions = training.repetitions,
            training_exercises = training.exercises;

        return {
            ...data,
            ...{
                warm_up_exercises,
                training_exercises,
                training_repetitions,
                cool_down_exercises,
            },
        }
    }

    onDataCollected = (data) => {
        const { warm_up_exercises, training_exercises, cool_down_exercises
            , training_repetitions } = data;

        const is_warm_up = warm_up_exercises.length ? true : false;
        const is_training = training_exercises.length ? true : false;
        const is_cool_down = cool_down_exercises.length ? true : false;

        const calcTotalTime = (exercises) => {
            return exercises.reduce((sum, { time }) => sum + (time || 0), 0)
        }

        const warm_up_general = {
            type: "warm_up_general",
            workout_id: -1,
            repetitions: 1,
            exercises: warm_up_exercises,
            total_time: calcTotalTime(warm_up_exercises),
            required_unique_exercises: countUniqueNonRest(warm_up_exercises),
            not_enough_content: false,
        }

        const training = {
            type: "training",
            workout_id: -1,
            repetitions: training_repetitions,
            exercises: training_exercises,
            total_time: calcTotalTime(training_exercises) * training_repetitions,
            required_unique_exercises: countUniqueNonRest(training_exercises),
            not_enough_content: false,
        }

        const cool_down = {
            type: "cool_down",
            workout_id: -1,
            repetitions: 1,
            exercises: cool_down_exercises,
            total_time: calcTotalTime(cool_down_exercises),
            required_unique_exercises: countUniqueNonRest(cool_down_exercises),
            not_enough_content: false,
        }

        const blocks = [
            is_warm_up ? warm_up_general : null,
            is_training ? training : null,
            is_cool_down ? cool_down : null,
        ].filter(x => x !== null);

        const blocks_total_time = (
            warm_up_general.total_time +
            cool_down.total_time +
            training.total_time
        );
        const p_time = data.p_time || Math.ceil(blocks_total_time / 60);

        return {
            ...data,
            ...{ blocks, p_time }
        }
    }
}
