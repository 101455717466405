import { API } from "aws-amplify";

import { selector, enumToOptions, enumToIntOptions } from "inputs/selector";
import { mapNullString, mapNullInt, mapInt } from "inputs/utils";
import { ExerciseOption } from "models/Exercise";


export const BlockExerciseModel = (constants, loadOptions) => {
    let objCache = {}
    return {
        id: {
            type: "select",
            validationRules: "required",
            onChangeEvent: "handleSelect",
            value: "",
            requestNormalizer: ({ id }) => id,
            responseNormalizer: (option) => {
                let exercise_id = option
                if (objCache[exercise_id] !== undefined) {
                    return objCache[exercise_id]
                }
                return API.get("admin", `/admin/exercises/${exercise_id}`)
                    .then(data => {
                        objCache[exercise_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                components: { ExerciseOption },
                getOptionLabel: option => {
                    return `[${option["id"]}]; ${option["name"]};`
                },
                getOptionValue: ({ id }) => id,
                pageSize: 10,
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
            },
            md: 4
        },
        type: selector(
            enumToIntOptions(constants, "workout_exercise_type"),
            mapInt,
            {
                validationRules: "required",
            },
        ),
        time: {
            requestNormalizer: mapNullInt,
            inputType: "number",
            value: "",
            validationRules: "required",
            md: 2,
        },
        repetitions: {
            inputType: "number",
            requestNormalizer: mapNullInt,  // API expects null if empty
            value: "",
            md: 2
        },
        target_area: selector(
            enumToOptions(constants, "target_area"),
            mapNullString,  // API expects null if empty
        ),
    }
}
