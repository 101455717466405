import React, { Component } from "react";

import List from "components/List/List";
import { BaseFilter } from "models/Base";
import { ApiConstantsContext } from "variables/ApiConstantsContext";
import defaults from "../../defaults"


export default class WbWorkoutList extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = "/admin/wb-workout/add";
        this.editPath = "/admin/wb-workout/edit"
    }

    render() {
        let userGroupsAllowedEdit = [defaults.userGroups.admin, defaults.userGroups.viewer, defaults.userGroups.editor]
        let readOnlyGroups = [defaults.userGroups.viewer]
        return (
            <List
                name="WB Workout"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/entity/WbWorkout"
                listApiUrl="/admin/wb-workout"
                model={[
                    { col: "Id", row: "id" },
                    { col: "Name", row: "name" },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={BaseFilter()}
                userHasPermission={this.props.userHasPermission}
                allowedEdit={userGroupsAllowedEdit}
                readOnlyGroups={readOnlyGroups}
            >
            </List>
        );
    }
}
