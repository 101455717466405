export const BaseFilter = () => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
    };
};