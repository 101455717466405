import React, {Component} from "react";
import {NavLink} from "react-router-dom";


class AddButton extends Component {
    render() {
        if (this.props.addPath) {
            return <NavLink
                to={{
                  pathname: this.props.addPath,
                  state: this.props.addState,
                }}
                className="btn btn-success"
            >
                Add
            </NavLink>
        }

        return ''
    }
}

export default AddButton;
