import React, { Component } from "react";

import List from "components/List/List";
import { BaseFilter } from "models/Base";
import { ApiConstantsContext } from "variables/ApiConstantsContext";
import defaults from "../../defaults"


export default class ChallengeList extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = "/admin/challenge/add";
        this.editPath = "/admin/challenge/edit"
    }

    render() {
        let userGroupsAllowedEdit = [defaults.userGroups.admin, defaults.userGroups.viewer, defaults.userGroups.editor]
        let readOnlyGroups = [defaults.userGroups.viewer]
        return (
            <List
                name="Challenge"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/entity/Challenge"
                listApiUrl="/admin/entity/Challenge?order_by=position"
                model={[
                    { col: "Id", row: "id" },
                    { col: "POS", row: "position" },
                    {
                        col: "Preview",
                        row: "preview",
                        normalizer: (data) => <img alt={data.name} src={data.preview} width="150" />
                    },
                    { col: "Name", row: "name" },
                    {
                        col: "Active",
                        row: "is_active",
                        normalizer: (data) => data.is_active ? "Yes" : "No"
                    },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={BaseFilter()}
                userHasPermission={this.props.userHasPermission}
                allowedEdit={userGroupsAllowedEdit}
                readOnlyGroups={readOnlyGroups}
            >
            </List>
        );
    }
}
