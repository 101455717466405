import React, {Component} from "react";

import List from "components/List/List";
import {BaseFilter} from "models/Base";
import {ApiConstantsContext} from "variables/ApiConstantsContext";

export default class Branch extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = "/admin/branch/add";
        this.editPath = "/admin/branch/edit";
    }

    render() {
        return (
            <List
                name="Branch"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/branch"
                listApiUrl="/admin/branch"
                model={[
                    { col: "Id", row: "id" },
                    { col: "Name", row: "name" },
                    { col: "Gender", row: "gender" },
                    {
                        col: "Preview",
                        row: "preview",
                        normalizer: (data) => <img alt={data.name} src={data.preview} width="150" />
                    },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={BaseFilter()}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }
}
