import React, {Component} from "react";
import List, {defaultGroups} from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {WorkoutFilter} from "../../models/Workout";
import EditButton from "../../components/List/EditButton";
import DeleteButton from "../../components/List/DeleteButton";
import Button from "react-bootstrap/lib/Button";
import {API} from "aws-amplify";

export default class Workout extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/workouts/add';
        this.editPath = '/admin/workouts/edit';
        this.state = {
          key: 0,
        };
    }

    setWorkoutStatus = (event, workout_id, status) => {
      let button = event.target;
      button.disabled = true;

      let url = `/admin/workouts/${workout_id}/status`;

      API.put("admin", url, { body: { is_published: status } })
        .then((data) => {
          this.props.handleClick(
            `Successfully set status to ${status}`,
            "success",
            "tr"
          );
          this.setState({ key: Math.random() });
        })
        .catch((error) => {
          this.props.handleClick(
            "Failed to set status: " + JSON.stringify(error.response.data),
            "error",
            "tr"
          );
        })
        .finally(() => {
          button.disabled = false;
        });
    };

    render() {
        let constants = this.context.constants;
        let get_const = this.context.get_const;
        let workouts_data = this.context.workouts_data;
        return (
            <List
                key={this.state.key}
                name="Workout"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/workouts"
                listApiUrl='/admin/workouts'
                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }
                    },
                    {col: 'Name', row: 'name'},
                    {
                      col: 'Workout Type',
                      row: 'workout_type_id',
                      normalizer: (data) => {
                        return workouts_data["types"][data.workout_type_id]
                      },
                    },
                    {col: 'Tag', row: 'tag'},
                    {col: 'Total time', row: 'total_time'},
                    {col: 'Total exercises', row: 'total_exercise_count'},
                    {
                      col: "Status",
                      row: "status",
                      normalizer: (data) => {
                        let colors = {
                          published: "green",
                          not_published: "orange",
                          soft_deleted: "red",
                        };
                        return (
                          <p style={{ color: colors[data.status] || "black" }}>
                            {get_const("workout_status", data.status)}
                          </p>
                        );
                      },
                    },
                ]}
                actions={[
                    (list, data_) => {
                        return <EditButton
                            editPath={list.props.editPath}
                            id={data_.id}
                            allowedGroups={list.props.allowedEdit || defaultGroups}
                        />

                    },
                    (list, data_) => {
                        return <DeleteButton
                            id={data_.id}
                            handleRemove={list.handleRemove}
                            allowedGroups={list.props.allowedDelete || defaultGroups}
                        />
                    },
                    (list, data) => {
                      if (data.status === "published") {
                        return (
                          <Button
                            className="btn btn-warning btn-fill"
                            onClick={(e) => this.setWorkoutStatus(e, data.id, false)}
                          >
                            Unpublish
                          </Button>
                        );
                      } else {
                        return (
                          <Button
                            className="btn btn-info btn-fill"
                            onClick={(e) => this.setWorkoutStatus(e, data.id, true)}
                          >
                            Publish
                          </Button>
                        );
                      }
                    },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={WorkoutFilter(constants, workouts_data)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }
}
