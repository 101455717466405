import React, {Component} from "react";
import List from "../../../components/List/List";
import {ApiConstantsContext} from "../../../variables/ApiConstantsContext";
import {BodyPartFilter} from "../../../models/BodyPart";

export default class BodyPart extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/exercises/body_parts/add';
        this.editPath = '/admin/exercises/body_parts/edit';
    }

    render() {
        return (
            <List
                name="BodyPart"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/exercises/body_parts"
                listApiUrl='/admin/exercises/body_parts'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                    {col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }},
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={BodyPartFilter()}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }
}
