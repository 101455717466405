import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {Tag} from "../../../models/Tag";


export default class TagAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Tag"
        this.url = "/admin/exercises/tags"
        this.redirectUrl = "/admin/exercises/tags"
    }

    getEntity = () => {
        return Tag();
    }
}
