import { selector, multiSelector, boolSelector, enumToOptions } from "inputs/selector";
import { BlockExerciseModel } from "models/BlockExerciseModel";
import { mapString, mapBool } from "inputs/utils";


const createExercisesLoader = (constants, loadOptions, kwargs = {}) => {
    return {
        type: "table_collection",
        prototype: BlockExerciseModel(constants, loadOptions),
        validationRules: kwargs.validationRules,
        onChangeEvent: "handleCollection",
        value: []
    }
}


export const WbWorkoutModel = (constants, loadOptions) => {
    return {
        should_be_deleted: { value: false, hidden: true },

        p_fitness_level: selector(
            enumToOptions(constants, "fitness_level"),
            mapString,
            { validationRules: "required" },
        ),
        p_time: {
            type: "number",
            requestNormalizer: parseInt,
        },
        p_method: selector(
            enumToOptions(constants, "workout_method"),
            mapString,
            { validationRules: "required" },
        ),
        with_equipment: selector(
            { "true": "Yes", "false": "No" },
            mapBool,
            { validationRules: "required" },
        ),
        p_equipment: multiSelector(
            enumToOptions(constants, "wb_equipment"),
            mapString,
        ),
        p_target_areas: multiSelector(
            enumToOptions(constants, "target_area"),
            mapString,
            { validationRules: "required" },
        ),
        p_type: selector(
            enumToOptions(constants, "wb_workout_type"),
            mapString,
            { validationRules: "required" },
        ),
        p_warm_up: boolSelector(mapBool, { validationRules: "required" }),
        warm_up_exercises: createExercisesLoader(constants, loadOptions),

        training_exercises: createExercisesLoader(constants, loadOptions),
        training_repetitions: {
            type: "number",
            validationRules: "required",
            requestNormalizer: parseInt,
        },

        p_cool_down: boolSelector(mapBool, { validationRules: "required" }),
        cool_down_exercises: createExercisesLoader(constants, loadOptions),
    }
}
