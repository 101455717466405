export const ExerciseTipModel = () => {
	return {
		name: {
			type: 'input',
			validationRules: 'required',
			value: '',
		},
		audio_url: {
			type: 'input',
			validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
			value: null,
		}
	}
}