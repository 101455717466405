import BaseTranslationComponent from "controllers/Generic/BaseTranslationComponent";
import { BaseFilter } from "models/Base";

import { URL_BASE } from "controllers/Translation/AudioTrack/lib";

export default class AudioTrackTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Audio Track Translations";
        this.addPath = `${URL_BASE}/add`;
        this.editPath = `${URL_BASE}/edit`;
        this.removeUrl = "";
        this.listApiUrl = URL_BASE;
    }

    generateFilter = () => {
        return BaseFilter();
    };
}
