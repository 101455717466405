import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import { BaseFilter } from "../../../models/Base";

import { URL_BASE } from "./lib";

export default class WbEquipmentTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "WB-Equipment Translations";
        this.addPath = `${URL_BASE}/add`;
        this.editPath = `${URL_BASE}/edit`;
        this.removeUrl = "";
        this.listApiUrl = URL_BASE;
    }

    generateFilter = () => {
        return BaseFilter();
    };
}
