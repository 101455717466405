import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {WorkoutTypeTranslationFilter} from "../../../models/WorkoutTypeTranslation";

export default class WorkoutTypeTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Workout Type Translations";
    this.addPath = "/admin/workouts/types/translations/add";
    this.editPath = "/admin/workouts/types/translations/edit";
    this.removeUrl = "/admin/workouts/types/translations";
    this.listApiUrl = "/admin/workouts/types/translations";
  }

  generateFilter = () => {
    return WorkoutTypeTranslationFilter();
  };
}
