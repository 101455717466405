import React, {Component} from "react";
import List, {defaultGroups} from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {WorkoutTypeFilter} from "../../models/WorkoutType";
import EditButton from "../../components/List/EditButton";

export default class WorkoutType extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/workouts/types/add';
        this.editPath = '/admin/workouts/types/edit';
    }

    render() {
        let constants = this.context.constants;

        return (
            <List
                name="Workout Type"
                addPath={this.addPath}
                editPath={this.editPath}
                listApiUrl='/admin/workouts/types'
                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }
                    },
                    {
                        col: 'Preview 2.0', row: 'preview_woplan_second', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview_woplan_second}
                                        width="150"/>
                        }
                    },
                    {col: 'Name', row: 'name'},

                ]}
                actions={[
                    (list, data_) => {
                        return <EditButton
                            editPath={list.props.editPath}
                            id={data_.id}
                            allowedGroups={list.props.allowedEdit || defaultGroups}
                        />

                    },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={WorkoutTypeFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
