import { BranchModel } from "models/Branch";
import BaseAddEditComponent from "controllers/Generic/BaseAddEditComponent";


export default class BranchAdd extends BaseAddEditComponent {

    constructor(props) {
        super(props);
        this.name = "Branch";
        this.url = "/admin/branch";
        this.redirectUrl = "/admin/branch"
    }

    getEntity = () => {
        return BranchModel(this.context.constants)
    }
}