export const ChallengeTranslationModel = (challenge_id, language, name, description) => {
    return {
        challenge_id: {
            value: challenge_id,
            hidden: true,
        },
        language: {
            value: language,
            hidden: true,
        },
        name: {
            type: "input",
            validationRules: "required",
            value: name,
        },
        description: {
            type: "input",
            value: description,
        },
    };
};
