import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {BaseFilter} from "../../../models/Base";

export default class ExerciseTipTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Exercise Tip Translations";
    this.addPath = "/admin/exercises/tips/translations/add";
    this.editPath = "/admin/exercises/tips/translations/edit";
    this.removeUrl = "/admin/exercises/tips/translations";
    this.listApiUrl = "/admin/exercises/tips/translations";
  }

  generateFilter = () => {
    return BaseFilter();
  };
}
