export const WorkoutTypeTranslationModel = (
  workout_type_id,
  language,
  name,
  audio_url,
  instruction
) => {
  return {
    workout_type_id: {
      value: workout_type_id,
      hidden: true,
    },
    language: {
      value: language,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: name,
    },
  };
};

export const WorkoutTypeTranslationFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    name: {
      type: "input",
      value: "",
    },
  };
};
