export const WbEquipmentTranslationModel = (
    equipment_id,
    language,
    name,
) => {
    return {
        equipment_id: {
            value: equipment_id,
            hidden: true,
        },
        language: {
            value: language,
            hidden: true,
        },
        name: {
            type: "input",
            validationRules: "required",
            value: name,
        },
    };
};
