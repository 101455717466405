import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import { ExerciseTranslationFilter } from "../../../models/ExerciseTranslation";

export default class ExerciseTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Exercise Translations";
    this.addPath = "/admin/exercises/translations/add";
    this.editPath = "/admin/exercises/translations/edit";
    this.removeUrl = "/admin/exercises/translations";
    this.listApiUrl = "/admin/exercises/translations";
  }

  generateFilter = () => {
    return ExerciseTranslationFilter();
  };
}
