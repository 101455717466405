
export const BranchTranslationModel = (
  branch_id,
  language,
  workout_name,
) => {
  return {
    branch_id: {
      value: branch_id,
      hidden: true,
    },
    language: {
      value: language,
      hidden: true,
    },
    workout_name: {
      type: "input",
      validationRules: "required",
      value: workout_name,
    },
  };
};

export const BranchTranslationFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    workout_name: {
      type: "input",
      value: "",
    },
  };
};
