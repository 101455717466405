import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import List from "../../components/List/List";
import { ApiConstantsContext } from "../../variables/ApiConstantsContext";
import { ExerciseFilter } from "../../models/Exercise";
import { dataList } from "../../utils/Utils";


export default class Exercise extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/exercise/add';
        this.editPath = '/admin/exercise/edit';
    }

    render() {
        let constants = this.context.constants;
        let exercises_data = this.context.exercises_data;
        return (
            <List
                name="Exercises"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/exercises"
                listApiUrl='/admin/exercises'
                model={[
                    { col: 'Id', row: 'id' },
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                width="150" />
                        }
                    },
                    {
                        col: 'Name', row: 'name', normalizer: (data) => {
                            if (data.media_url) {
                                return <a href={data.media_url} target="_blank" rel="noopener noreferrer">{data.name}</a>
                            }
                            return data.name
                        }
                    },

                    {
                        col: 'Body Parts',
                        row: 'body_parts',
                        normalizer: (data) => {
                            return dataList(exercises_data, data, "body_parts");
                        },
                    },
                    {
                        col: 'Equipment',
                        row: 'equipments',
                        normalizer: (data) => {
                            return dataList(exercises_data, data, "equipments");
                        },
                    },
                    { col: 'Base Pace', row: 'base_pace' },
                    { col: 'Base Mets', row: 'base_mets' },
                    { col: '1RM', row: 'one_rm' },
                    {
                        col: 'Tags',
                        row: 'tags',
                        normalizer: (data) => {
                            return dataList(exercises_data, data, "tags");
                        },
                    },
                    {
                        col: 'Relations',
                        row: 'related_exercises',
                        normalizer: (data) => {
                            if (!data.related_exercises) {
                                return
                            }
                            return data.related_exercises.join(',')
                        },
                    },
                    {
                        col: 'Instructions',
                        row: 'instructions',
                        normalizer: (data) => {
                            if (!data.instructions) {
                                return
                            }
                            return (
                                <ul>
                                    {data.instructions.map((instructionData) => {
                                        let rowEnd = instructionData.name.length > 20 ? '...' : ""
                                        return <li>{instructionData.name.substring(0, 20)}{rowEnd}</li>;
                                    })}
                                </ul>
                            );
                        },
                    },
                    {
                        col: 'Tips',
                        row: 'tips',
                        normalizer: (data) => {
                            if (!data.tips) {
                                return
                            }
                            return data.tips.map((tip_id, i) =>
                                <div key={i}>
                                    {`${i + 1}. `}
                                    <NavLink to={'/admin/exercises/tips/edit/' + tip_id}>
                                        {tip_id}
                                    </NavLink>
                                </div>)
                        },
                    },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={ExerciseFilter(constants, exercises_data)}
                userHasPermission={this.props.userHasPermission}
                showImportButton={true}
                showImportTranslations={true}
            >
            </List>

        );
    }
}
