import React, {Component} from "react";
import {API} from "aws-amplify";

const ApiConstantsContext = React.createContext();

class ApiConstantsContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            constants: {},
            exercises_data: {},
            workouts_data: {},
            get_const: (key, item) => {
              if (!this.state.constants) {
                return null;
              }
              if (item) {
                return this.state.constants[key]["items"][item]["title"];
              }
              return this.state.constants[key]["title"];
            },
        }
    }

    componentDidMount() {
        API.get('admin', '/admin/constants')
            .then(data => {
                this.setState({
                  constants: data,
                });
            }).catch(error => {
            console.log("Error " + error);
        })

        let element_list = ["equipments", "body_parts", "tags"]
        let exercises_data = {}
        element_list.forEach(element => {
            API.get('admin', '/admin/exercises/' + element,
              {
              queryStringParameters: {
                per_page: "1000",
              }
            })
              .then(data => {
                exercises_data[element] = data.items.reduce(function (obj, item) {
                  // obj[item.id] = `[${item.id}] ${item.name}`;
                  obj[item.id] = item.name;
                  return obj;
                }, {});
              }).catch(error => {
                console.log("Error " + error);
            })
        })
        this.setState({
            exercises_data: exercises_data,
        })

        API.get('admin', '/admin/workouts/types', {
          queryStringParameters: {
            per_page: "1000",
          }
        })
            .then(data => {
                let workouts_data = {}
                workouts_data["types"] = data.items.reduce(function (obj, item) {
                  obj[item.id] = item.name;
                  return obj;
                }, {});
                this.setState({
                  workouts_data: workouts_data,
                });
            }).catch(error => {
            console.log("Error " + error);
        })

    }


    render() {
        return (
            <ApiConstantsContext.Provider value={this.state}>
                {this.props.children}
            </ApiConstantsContext.Provider>
        );
    }
}


export {ApiConstantsContextProvider, ApiConstantsContext};


