import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {ExerciseTipModel} from "../../../models/ExerciseTip";


export default class ExerciseTipAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Exercise Tip"
        this.url = "/admin/exercises/tips"
        this.redirectUrl = "/admin/exercises/tips"
    }

    getEntity = () => {
        return ExerciseTipModel();
    }
}
