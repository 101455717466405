import React, {Component} from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import FormRow from "./FormRow";
import {Draggable} from "react-beautiful-dnd";

class TableCollectionRow extends Component {
    constructor(props) {
        super(props);
        this.object = this.props.object;
        this.state = this.props.value;
    }

    componentWillReceiveProps(props) {
        // Fixme https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
        this.setState(props.value);
        this.object = props.object;
    }

    handleInput = (event) => {
        this.setState({[event.target.name]: event.target.value}, () => {
            this.props.handleChange(this.state, this.props.index);
        });
    };
    handleSelect = (value, event) => {
        this.setState({[event.name]: value}, () => {
            this.props.handleChange(this.state, this.props.index);
        });
    };

    render() {
        return (
            <Draggable
                key={this.props.index}
                draggableId={"item-" + this.props.index}
                index={this.props.index}>
                {(provided, snapshot) => (
                    <tr ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        {Object.keys(this.object).map((key, index) => {
                            if (!this.object[key].hidden) {
                                return (
                                    <td
                                        className={`col-md-${this.object[key].md || 2}`}
                                        key={index}
                                    >
                                        <FormRow
                                            key={index}
                                            name={key}
                                            type={this.object[key].type || "input"}
                                            inputType={this.object[key].inputType}
                                            value={
                                                this.state[key] === null ? undefined : this.state[key]
                                            }
                                            validationRules={this.object[key].validationRules || ""}
                                            onChangeEvent={this[this.object[key].onChangeEvent] || this.handleInput}
                                            selectOptions={this.object[key].selectOptions || null}
                                            validator={this.props.validator}
                                            label={false}
                                            selectProps={this.object[key].selectProps}
                                        />
                                    </td>
                                );
                            }

                            return null;
                        })}
                        <td>
                            <Button
                                block
                                bsStyle="danger"
                                className="btn btn-fill btn-danger btn-xs"
                                onClick={(e) => {
                                    if (
                                        window.confirm("Are you sure you want to delete this item?")
                                    ) {
                                        this.props.onDelete(e, this.props.index);
                                    }
                                }}
                            >
                                <i className="fa fa-times"/>
                            </Button>
                        </td>
                    </tr>
                )}
            </Draggable>
        );
    }
}

export default TableCollectionRow;
