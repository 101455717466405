import BaseTranslationComponent from "controllers/Generic/BaseTranslationComponent";
import { BaseFilter } from "models/Base";

import { URL_BASE } from "./lib";


export default class ChallengeTranslation_List extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Challenge Translation";
        this.addPath = `${URL_BASE}/add`;
        this.editPath = `${URL_BASE}/edit`;
        this.removeUrl = "";
        this.listApiUrl = URL_BASE;
    }

    generateFilter = () => {
        return BaseFilter();
    };
}
