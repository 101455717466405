import React, {Component} from "react";
import List from "../../components/List/List";
import {WorkoutBlockFilter} from "../../models/WorkoutBlock";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {NavLink} from "react-router-dom";

export default class WorkoutBlock extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/workout-blocks/add';
        this.editPath = '/admin/workout-blocks/edit';
    }

    render() {
        let constants = this.context.constants;

        return (
            <List
                name="Workout Block"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/workout-blocks"
                listApiUrl='/admin/workout-blocks'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                    {col: 'Total time', row: 'total_time'},
                    {
                        col: 'Exercises', row: 'exercises', normalizer: (data) => {
                            return data.exercises.map((a, i) =>
                                <div key={i}>{`${i + 1}. ${a.name}`} </div>)
                        }
                    },
                    {
                        col: 'Workouts', row: 'workouts', normalizer: (data) => {
                            return data.workouts.map((a, i) =>
                                <div key={i}>
                                  <NavLink to={'/admin/workouts/edit/' + a.workout_id}>
                                  {`${i + 1}. ${a.name} (${a.position + 1})`}
                                  </NavLink>
                                </div>)
                        }
                    },

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={WorkoutBlockFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
