export const AudioTrackModel = () => {
	return {
		name: {
			type: "input",
			validationRules: "required",
			value: "",
		},
		time: {
			inputType: "number",
			value: "",
			validationRules: "required",
			requestNormalizer: (data) => {
				return parseInt(data)
			}
		},
		repetitions: {
			inputType: "number",
			value: 1,
			validationRules: "required",
			requestNormalizer: (data) => {
				return parseInt(data)
			}
		},
		universal_audio_track_category_id: {
			inputType: "number",
			value: "",
			validationRules: "required",
			requestNormalizer: (data) => {
				return parseInt(data)
			}
		}
	}
}