import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../Loader/Loader";
import FormRow from "./FormRow";
import { API } from "aws-amplify";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: props.entity,
            isLoading: true,
            responseData: {},
            resourceData: {},
            defaults: {},
        };
        this.validator = new SimpleReactValidator({ className: "text-danger" });
    }

    getDefaultValues = async (entity) => {
        entity = entity || this.state.entity;
        const initialValues = {};

        Object.keys(entity)
            .filter(value => value !== "__validator__")
            .map(key => initialValues[key] = entity[key].value);

        // Response maps against {key: label} dict which may not exist
        // at this moment (still being fetched over the Internet)
        const data = this.props.responseData || this.state.responseData;

        const onDataReceived = this.props.onDataReceived || (data => data);
        const data1 = onDataReceived(data);

        if (Object.keys(data1).length !== 0) {
            await this.mapData("responseNormalizer", initialValues, data1, entity)
        }
        Object.assign(initialValues, this.state.resourceData);
        return initialValues
    }

    prepareRequestData = async () => {
        let requestData = {};
        const defaults = await this.getDefaultValues();
        await this.mapData("requestNormalizer", requestData, defaults, this.state.entity);

        const onDataCollected = this.props.onDataCollected || (data => data);
        const requestData1 = onDataCollected(requestData);

        return requestData1
    }

    handleSubmit = async (event) => {
        if (this.validator.allValid()) {
            event.preventDefault();
            let requestData = { body: await this.prepareRequestData() };
            let actionUrl =
                (this.props.actionUrl || this.props.url) +
                (this.props.id ? "/" + this.props.id : "");
            let resultPromise = this.props.id
                ? API.put("admin", actionUrl, requestData)
                : API.post("admin", actionUrl, requestData);

            resultPromise
                .then((data) => {
                    if (this.props.onSuccess) {
                        event.preventDefault();
                        this.props.onSuccess();
                    }
                })
                .catch((error) => {
                    this.props.handleClick(
                        error.response.data.error || error.response.data.message,
                        "error",
                        "tr"
                    );
                });
        } else {
            this.validator.showMessages();
            event.preventDefault();
            this.forceUpdate();
        }
    };

    handleInput = (event) => {
        const {
            target: { name, value },
        } = event;

        this.reloadDefaults(name, value)
    };
    handleSelect = (value, event) => {
        this.reloadDefaults(event.name, value)
    };
    handleCollection = (_State, name) => {
        this.reloadDefaults(name, _State)
    };

    mapDataNested = async (normalizerFuncName, data, nestedModel) => {
        return await Promise.all(
            Object.keys(data || {}).map(async (key) => {
                const result = {};
                await this.mapData(normalizerFuncName, result, data[key], nestedModel);
                return result
            })
        )
    }

    mapData = async (normalizerFuncName, result, data, model) => {
        const modelKeys = Object.keys(model).filter(
            value => value !== "__validator__"
        );
        for (const key of modelKeys) {
            const modelProp = model[key],
                dataObject = data[key];

            // If there is a nested model within current {model},
            // we should perform recursion of {mapData} for every key of {data}
            const nestedModel = modelProp.prototype;
            const dataObject1 = nestedModel !== undefined
                ? await this.mapDataNested(normalizerFuncName, dataObject, nestedModel)
                : dataObject;

            // Adding value to result (as is or via normalizer)
            const dataNormalizer = modelProp[normalizerFuncName];
            result[key] = dataNormalizer
                ? await Promise
                    .resolve(dataNormalizer(dataObject1, data))
                    .then(value => value)
                : dataObject1;
        }
    };

    reloadDefaults(name, value) {
        this.setState((prevState) => {
            let resourceData = Object.assign({}, prevState.resourceData);
            let defaults = Object.assign({}, prevState.defaults)
            resourceData[name] = value;
            defaults[name] = value;
            return { resourceData, defaults }
        });
    }

    componentDidMount() {
        if (!this.props.skipPrepopulation && this.props.id) {
            API.get(
                "admin",
                this.props.getEntityUrl || this.props.url + "/" + this.props.id
            ).then((data) => {
                this.setState({ responseData: data });

                this.getDefaultValues().then((value) => {
                    this.setState({ defaults: value, isLoading: false });
                });
            });
        } else {
            this.getDefaultValues().then((value) => {
                this.setState({ defaults: value, isLoading: false });
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const entity = nextProps.entity
        this.getDefaultValues(entity).then((value) => {
            this.setState({ defaults: value, entity: entity });
        });
    }

    render() {
        let defaults = this.state.defaults;
        let title = this.props.title;
        if (typeof this.props.title === "function") {
            title = this.props.title(defaults);
        }
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={this.props.md || 6}>
                            <Card
                                title={title}
                                content={
                                    <Loader isLoading={this.state.isLoading}>
                                        <form onSubmit={this.handleSubmit}>
                                            {Object.keys(this.state.entity)
                                                .filter((value) => value !== "__validator__")
                                                .map((key, index) => {
                                                    if (!this.state.entity[key].hidden) {
                                                        return (
                                                            <FormRow
                                                                key={index}
                                                                name={key}
                                                                altname={this.state.entity[key].altname}
                                                                type={this.state.entity[key].type || "input"}
                                                                inputType={this.state.entity[key].inputType}
                                                                value={defaults[key]}
                                                                validationRules={
                                                                    this.state.entity[key].validationRules || ""
                                                                }
                                                                onChangeEvent={
                                                                    this[this.state.entity[key].onChangeEvent] ||
                                                                    this.handleInput
                                                                }
                                                                selectOptions={
                                                                    this.state.entity[key].selectOptions || null
                                                                }
                                                                prototype={
                                                                    this.state.entity[key].prototype || null
                                                                }
                                                                validator={this.validator}
                                                                md={this.state.entity[key].md || null}
                                                                selectProps={this.state.entity[key].selectProps}
                                                                loadLoadOptionEverytime={
                                                                    this.state.entity[key].loadLoadOptionEverytime ||
                                                                    false
                                                                }
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            <Button
                                                bsStyle="primary"
                                                type="submit"
                                                onClick={async (e) => this.handleSubmit(e)}
                                                disabled={this.props.userReadOnly}
                                            >
                                                Save
                                            </Button>
                                            <div className="clearfix" />
                                        </form>
                                    </Loader>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Form;
