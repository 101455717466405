import BaseAddEditComponent from "controllers/Generic/BaseAddEditComponent";
import { AudioTrackTranslationModel } from "models/AudioTrackTranslation";

import { URL_BASE } from "./lib";

export default class AudioTrackTranslation_Add extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Audio Track Translation";
        this.url = URL_BASE;
        this.skipPrepopulation = true;
    }

    locationState = () => {
        return this.props.location.state || { language: {}, translation: {} };
    };

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`;
    };

    getEntity = () => {
        let state = this.locationState();
        let translation = state.translation || {};

        return AudioTrackTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || "",
            translation.url || "",
        );
    };
}
