const EMPTY = undefined;


const mapSelect = (options, key) => {
    return { value: key || EMPTY, label: options[key] }
}

export const selector = (options, mapValue, kwargs = {}) => {
    return {
        type: "select",
        validationRules: kwargs.validationRules,
        onChangeEvent: "handleSelect",

        requestNormalizer: (data) => mapValue((data || {}).value),
        responseNormalizer: (key) => mapSelect(options, key),

        selectOptions: Object.keys(options).map(key => mapSelect(options, key)),
    }
}

export const multiSelector = (options, mapValue, kwargs = {}) => {
    const mapInnerValue = ({ value }) => mapValue(value);
    return {
        type: "multiSelect",
        validationRules: kwargs.validationRules,
        onChangeEvent: "handleSelect",

        requestNormalizer: (items) => (items || []).map(mapInnerValue),
        responseNormalizer: (items) => (items || []).map(key => mapSelect(options, key)),

        selectOptions: Object.keys(options).map(function (key) {
            return mapSelect(options, key)
        }),
    }
}

export const boolSelector = (mapValue, kwargs = {}) => {
    return selector(
        { "true": "Yes", "false": "No" },
        mapValue,
        kwargs,
    )
}

export const enumToOptions = (constants, key) => {
    let data = constants[key] === undefined ? {} : constants[key].items || {};
    return Object.fromEntries(Object.entries(data).map(([k, v]) => [k, v.title]))
}

export const enumToIntOptions = (constants, key) => {
    let data = constants[key] === undefined ? {} : constants[key].items || {};
    return Object.fromEntries(Object.entries(data).map(([k, v]) => [v.value, v.title]))
}
