import {components} from "react-select";
import {buildSelector, constToSelectOptions, generateBadgeRow, MultipleChoiceSelector} from "../utils/Utils";
import {API} from "aws-amplify";
import {NavLink} from "react-router-dom";
import React from "react";


const Option = ({children, ...props}) => {
    let option = props.data
    let exercises = (option.exercises || []).map((val) => val.name);
    return (
        <components.Option {...props}>
            <div className="clearfix">
                <p style={{"margin": "0"}}>
                        <NavLink
                            to={{
                                pathname: "/admin/workout-blocks/edit/" + option.id,
                            }}
                            target="_blank"
                            style={{color: 'black'}}
                        >
                            <strong>[{option.id}] {option.name}</strong> <span
                            className="glyphicon glyphicon-new-window"/>
                        </NavLink>
                    </p>

                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <br />
                        {generateBadgeRow("Exercises", exercises, "success")}
                    <br />
                    <span style={{ margin: "0" }}>
                        Total Time: <strong>{option.total_time}</strong>
                    </span>
                </div>

            </div>
        </components.Option>
    );
};


export const WorkoutBlockModel = (constants, loadOptions) => {
    let objCache = {}
    return {
        block_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: (option) => {
                return option.id
            },
            responseNormalizer: (option) => {
                let block_id = option
                if (objCache[block_id] !== undefined) {
                    return objCache[block_id]
                }
                return API.get('admin', `/admin/workout-blocks/${block_id}`)
                    .then(data => {
                        objCache[block_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                components: {Option},
                getOptionLabel: option => {
                    return `[${option['id']}] ${option['name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
                // defaultOptions: true,
            },
            md: 8
        },
        type: {
            ...buildSelector(constToSelectOptions(constants, 'workout_block_type'), 'select'),
            value: null,
        },

        loop_count: {
            inputType: 'number',
            value: 1,
            requestNormalizer: function (data) {
                return parseInt(data);
            },
            md: 2
        },

    }
}


export const Workout = (constants, loadOptions, workouts_data) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
            md: 4
        },
        preview: {
            type: 'input',
            value: '',
            md: 4
        },

        workout_type_id: buildSelector(
            workouts_data.types,
            'select',
            (data) => {
                return parseInt(data.value)
            },
          ),

        tag: {
            type: 'input',
            validationRules: 'required',
            value: '',
            md: 4
        },
        blocks: {
            type: 'table_collection',
            value: [],
            prototype: WorkoutBlockModel(constants, loadOptions),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
        },
    }
};


export const WorkoutFilter = (constants, workouts_data) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
        tag: {
            type: 'input',
            value: '',
        },
        workout_types: MultipleChoiceSelector(workouts_data.types),
        status: buildSelector(
          constToSelectOptions(constants, "workout_status")
        ),
    };
};
