import React from 'react';
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import debounce from "debounce-promise";

import { WorkoutBlock } from "models/WorkoutBlock";
import { loadExercises } from 'controllers/Exercises/lib';


export default class WorkoutBlockAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Workout Block";
        this.url = "/admin/workout-blocks";
        this.debouncedLoadOptions = debounce(loadExercises, 2000)
    }

    getEntity = () => {
        return WorkoutBlock(this.context.constants, this.debouncedLoadOptions);
    }

    render() {
        return React.cloneElement(
            super.render(),
            { md: 12 }
        )
    }
}