import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {WorkoutTranslationFilter} from "../../../models/WorkoutTranslation";

export default class WorkoutTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Workout Translations";
    this.addPath = "/admin/workouts/translations/add";
    this.editPath = "/admin/workouts/translations/edit";
    this.removeUrl = "/admin/workouts/translations";
    this.listApiUrl = "/admin/workouts/translations";
  }

  generateFilter = () => {
    return WorkoutTranslationFilter();
  };
}
