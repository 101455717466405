import { ChallengeModel } from "models/ChallengeModel";
import BaseAddEditComponent from "controllers/Generic/BaseAddEditComponent";


export default class ChallengeAdd extends BaseAddEditComponent {

    constructor(props) {
        super(props);
        this.name = "Challenge";
        this.url = "/admin/challenge";
        this.redirectUrl = "/admin/challenge"
    }

    getEntity = () => {
        return ChallengeModel(this.context.constants, [])
    }

    onDataCollected = (data) => {
        const { workout_links } = data;
        return (
            workout_links.length > 0 ? data : {
                ...data, ...{ is_active: false }
            }
        )
    }

}
