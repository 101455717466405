import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {BranchTranslationFilter} from "../../../models/BranchTranslation";

export default class BranchTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "Branch Translations";
    this.addPath = "/admin/branch/translations/add";
    this.editPath = "/admin/branch/translations/edit";
    this.removeUrl = "/admin/branch/translations";
    this.listApiUrl = "/admin/branch/translations";
  }


  getBranchName = (data) => data.branch_name;

    generateModel() {
    let constants = this.context.constants;
    let languages = Object.values(
      constants.language === undefined ? {} : constants.language.items || {}
    );
    languages.sort((a, b) => a.value - b.value);
    let model = [{ col: "Id", row: "id" }];
    model.push({
      col: "Name",
      row: "name",
      normalizer: (data) => {
        return this.getBranchName(data)
      }
    });
    for (const lang of languages) {
      model.push({
        col: lang.title,
        row: "languages",
        normalizer: (data) => {
          return this.generateAddEditLink(
            data.translations[lang.key],
            data.id,
            lang
          );
        },
      });
    }
    return model;
  }

  generateFilter = () => {
    return BranchTranslationFilter();
  };
}
