export const WorkoutTypeModel = () => {
	return {
		name: {
			type: 'input',
			validationRules: 'required',
			value: '',
		},
		preview: {
			type: 'input',
			validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}, 'required'],
			value: null,
		},
		preview_woplan_second: {
			type: 'input',
			validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}, 'required'],
			value: null,
		},
	};
}

export const WorkoutTypeFilter = () => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
    };
};
