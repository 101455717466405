import React, {Component} from "react";
import List from "../../../components/List/List";
import {BaseFilter} from "../../../models/Base";
import {ApiConstantsContext} from "../../../variables/ApiConstantsContext";

export default class ExerciseTip extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/exercises/tips/add';
        this.editPath = '/admin/exercises/tips/edit';
    }

    render() {
        return (
            <List
                name="Tip"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/exercises/tips"
                listApiUrl='/admin/exercises/tips'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={BaseFilter()}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }
}
